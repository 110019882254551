.home-background {
  background-image: linear-gradient(to bottom, rgba(3,28,37,0.8449754901960784) 0%, rgba(84,192,255,0.6825105042016807) 73%), url('assets/img/mountains.jpg');
  /* Set a specific height */
  min-height: 105vh;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  animation: fadein 5s;
}


.signup-background {
  background-image: linear-gradient(to bottom, rgba(3,28,37,0.8449754901960784) 0%, rgba(84,192,255,0.6825105042016807) 73%), url(assets/img/login.jpg);
  background-size: cover;
  height:100vh;

}
.gtfo {
  padding: 0;
  color: #FFFFFF;
}
.gtfo:before {
  background-color: rgba(0, 0, 0, 0.3); }


.gtfo .footer {
  position: absolute;
  bottom: 0;
  width: 100%; }