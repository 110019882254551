


.card {
    position: relative;
    border-radius: 8px;
    height: 40vh;
    width: 40vh;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    margin: auto;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    box-shadow: 0 0 80px -10px black;
    overflow: hidden;
    box-shadow: 0 0 10px #9ecaed;
}

.card-blur {
    position: absolute;
    height: 100%;
    width: calc(100% + 1px);
    background-color: black;
    opacity: 0;
    transition: opacity 0.15s ease-in;
}

.card:hover .card-blur {
    opacity: 0.6;
}

.footerCard {
    z-index: 1;
    position: absolute;
    height: 25%;
    width: 100%;
    bottom: 0;
}

svg#curve {
    position: absolute;
    fill: #1990CE;
    left: 0;
    bottom: 0;
    width: 400px;
    height: 450px;
}

.connections {
    height: 30px;
    width: 150px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100px;
    margin: auto;
}

.connection {
    height: 10px;
    width: 10px;
    border-radius: 100%;
    background-color: white;
    display: inline-block;
    padding: 2px;
    margin-right: 25px;
    transform: translateY(200px);

    transition: transform 1s cubic-bezier(.46, 1.48, .18, .81);
}

.card:hover .connection {
    transform: translateY(0px);
}

.info {
    font-family: Inconsolata;
    padding-left: 20px;
    transform: translateY(250px);

    transition: transform 1s cubic-bezier(.31,1.21,.64,1.02);
}

.card:hover .info {
    transform: translateY(0px);
}

.name {
    font-weight: bolder;
    padding-top: 5px;
}

.job {
    margin-top: 10px;
}


.connection.twitter {
    transition-delay: 0.06s;
}

.connection.behance {
    transition-delay: 0.12s;
}
