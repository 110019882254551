html {

}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;

}
@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#screen p {
  font-size: 21px;
  text-align: center;

  animation: fadein 2s;
}
.investbutton {
  box-shadow: 0 0 5px #9ecaed !important;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

@media(max-width: 500px) {
  .logo {
    width:80vw !important;
  }
  .investbutton {
      margin-top:10vh !important;
      width:20vh !important;
      height:7vh!important;;
  }
  .teamTitle {
    font-size:4vh !important;
  }
  .space {
    height: 10vh !important;
  }
  .stack {
    flex-direction:row !important;
  }
  .contact {
    padding-left:3vw !important;
  }
  .social {
    margin-left: 0 !important;
  }
  .socialIcon {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .footerCard {
    height: 30% !important;
  }
}
@media(max-width: 970px) {
  .profileCard {
    height:105vh !important;
    margin-left:2vw!important;
    margin-right:2vw!important;
  }
  .profileBox {
    flex-direction:column !important;
  }
  #team {
    height:130vh !important;
  }
  .profileText {
    width:90vw !important;
    text-align: left  !important;
    margin-left: 0 !important;
    padding-top: 4vh !important;
  }
  .card {
    margin-top:2vh !important;
    margin-left:12% !important;
    height: 30vh !important;
    width: 30vh !important;
  }
}

