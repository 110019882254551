.line1 {
    width:0px;
    border-bottom:2px solid #FFFFFF;

    -webkit-animation: increase 1s;
    -moz-animation:    increase 1s;
    -o-animation:      increase 1s;
    animation:         width 3s;
    animation-fill-mode: forwards;
}
.line2 {
    width:0px;
    border-bottom:2px solid #FFFFFF;
    float:right;

    -webkit-animation: increase 1s;
    -moz-animation:    increase 1s;
    -o-animation:      increase 1s;
    animation:         width 3s;
    animation-fill-mode: forwards;
}


.height1 {
    height:0px;
    border-right:2px solid #FFFFFF;
    animation:         height 3s;
    animation-fill-mode: forwards;
}
.height2 {
    height:0px;
    border-left:2px solid #FFFFFF;

    -webkit-animation: increase 1s;
    -moz-animation:    increase 1s;
    -o-animation:      increase 1s;
    animation:         height 3s;
    animation-fill-mode: forwards;
}

@keyframes width {
    100% {
        width: 100%;
    }
}
@keyframes height {
    100% {
        height: 100%;
    }
}